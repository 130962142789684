/* src/components/Header.css */
:root {
    --vh: 100vh;
}

#home {
    position: relative;
    height: calc(var(--vh, 1vh) * 100);
    color: white;
    text-align: center;
    overflow: hidden;
}

#nav-wrap {
    position: absolute;
    top: 0;
    width: 100%;
    background: rgba(0, 0, 0, 0);
    padding: 10px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10;
    /* Ensure the nav is above the particles */
}

.nav {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    justify-content: center;
    gap: 20px;
    transition: transform 0.3s ease-in-out;
    font-weight: 500;
    font-size: 1.4em;
}

.nav li {
    display: inline;
}

.nav a {
    color: white;
    text-decoration: none;
    padding: 10px 20px;
}

.nav a:hover {
    color: #009900;
}

.mobile-btn {
    display: none;
    font-size: 24px;
    color: white;
    background: none;
    border: none;
    cursor: pointer;
    position: absolute;
    right: 20px;
    top: 50%;
    transform: translateY(-50%);
}

.banner {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
}

.responsive-headline {
    font-size: clamp(36px, 8vw, 140px);
    font-weight: 700;
    margin-bottom: 0;
}

.responsive-description {
    font-size: clamp(16px, 3vw, 52px);
    font-weight: 300;
    margin-top: 5px;
}

@media (max-width: 768px) {
    #nav-wrap {
        justify-content: space-between;
        padding: 30px 0px;
    }

    .mobile-btn {
        display: block;
        z-index: 10;
    }

    .nav {
        flex-direction: column;
        position: absolute;
        top: 0px;
        left: 0;
        right: 0;
        background: rgba(0, 0, 0, 0.6);
        transform: translateY(-150%);
        visibility: hidden;
        transition: transform 0.3s ease-in-out, visibility 0.3s ease-in-out;
        padding: 20px 0px;
    }

    .nav-open {
        transform: translateY(0);
        visibility: visible;
    }
}